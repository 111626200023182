<template>
    <div class="px-2 py-1">
        <div v-if="item[column]" class="flex items-center justify-center h-6 w-6 rounded-full bg-white border border-green-300 text-green-700">
            <i class="fal fa-check"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: "base-column-check",
    // props: ['column', 'item'],
    props: {
        'column': { type: String },
        'item': { type: Object }
    }
}
</script>

<style scoped>

</style>